function Footer() {

  return (
    <footer className="footer">
      <p className="footer__copyright">© 2020 Around the U.S.</p>
    </footer>
  );
  
}

export default Footer;
